<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import offerList from './offerList.vue'

export default {
  data() {
    return {
      tabs: [
        {
          title: 'Tüm Teklifler',
          component: offerList,
        },
        {
          title: 'Cevap Beklenenler',
        },
        {
          title: 'Onaylananlar',
        },
        {
          title: 'Reddedilenler',
        },
      ],
    }
  },
}
</script>
