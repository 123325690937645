<template>
  <searchable-table :items="[]">
    <template #modalbutton>
      <app-button
        text="Teklif Ekle"
        icon="PlusIcon"
        size="md"
      />
    </template>
  </searchable-table>
</template>

<script>
export default {}
</script>

<style></style>
